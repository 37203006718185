<template>
  <div class="winlose-lists">
    <div>
      <WinloseFilter
        :date-start.sync="dateStart"
        :date-end.sync="dateEnd"
        :time-start.sync="timeStart"
        :time-end.sync="timeEnd"
        :selected-game.sync="selectedGame"
        :selected-game-checkboxs.sync="selectedGameCheckboxs"
        :passed-query.sync="passedQuery"
        :search-username.sync="searchUsername"
        :is-search-username="isSearchUsername"
        @onSearch="onSearch"
      />
    </div>
    <div>
      <div>
        <span class="badge badge-primary font-weight-bolder">{{ groupType }}</span>
      </div>
      <ReportComponent
        :items="items"
        :summary="summary"
        :upline-type.sync="uplineType"
        :is-simple="isSimple"
        :ref-key-text="refKeyText"
        :group-type="groupType"
        :group-id="groupId"
      />
    </div>
  </div>
</template>

<script>
import ReportComponent from '@/components/ReportComponent.vue'
import WinloseFilter from '@/views/reports/WinloseFilter.vue'
import dayjs from '@/libs/dayjs'
import { GameLists } from '@/utils/report'
import store from '@/store'
import { GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType } from '@/utils/agent'

export default {
  components: {
    ReportComponent,
    WinloseFilter,
  },
  props: {
    //   'username'
    refKeyText: { type: String, required: true },
    // W/L Member (Detail)
    title: { type: String, required: true },

    //   /reports/member_winlose_simple
    urlPath: { type: String, required: true },

    //   reports-agent-winlose-simple
    routeName: { type: String, required: true },

    isSimple: { type: Boolean, required: true },
    isSearchUsername: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      routeData: {},

      // diff
      //   refKeyText: 'username',
      //   title: 'W/L Member (Detail)',

      items: [
      ],
      summary: {},
      uplineType: '', // same as groupType

      // search
      dateStart: dayjs().format('YYYY-MM-DD'),
      dateEnd: dayjs().add(1, 'days').format('YYYY-MM-DD'),
      timeStart: '00:00',
      timeEnd: '00:00',
      selectedGame: '',
      selectedGameCheckboxs: [],
      searchUsername: '',
      passedQuery: false,

      allowQueryKeys: [
        "dateStart",
        "dateEnd",
        "timeStart",
        "timeEnd",
        "selectedGame",
        "selectedGameCheckboxs",
        "searchUsername",
        "isSimple",
      ],

      groupType: '', // same as uplineType
      groupId: '',
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
  },
  watch: {
    $route(to, from) {
      if ([
        'reports-member-winlose-simple',
        'reports-member-winlose-detail',

        'reports-agent-winlose-simple',
        'reports-agent-winlose-detail',
        'test-reports-agent-winlose-simple',
        'test-reports-agent-winlose-detail',

        'reports-match-winlose-simple',
        'reports-match-winlose-detail',
      ].some(list => list === to.name)) {
        // let groupId
        // if ('groupId' in this.$route.params && this.$route.params.groupId) {
        //   groupId = this.$route.params.groupId
        // }

        // this.groupId = groupId || ''
        // if (!groupId) {
        //   store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')
        // }
        this.setRouteParamAndQuery()

        if (!this.groupId) {
          store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')
        }

        this.fetchData()
      }
    },
  },
  mounted() {
    if (!this.uplineType || this.uplineType === '') {
      this.uplineType = this.GetAgentGroupType(this.userData)
    }
    this.setRouteParamAndQuery()
    this.onSearch()
    // if (Object.keys(this.$route.query).length > 0) {
    //   this.fetchData()
    // }
  },
  beforeDestroy() {
    this.$wait.end('reports-loading')
  },
  methods: {
    GetAgentGroupId,
    GetAgentGroupUsername,
    GetAgentGroupType,
    setRouteParamAndQuery() {
      // query params
      if ('groupId' in this.$route.params && this.$route.params.groupId) {
        this.groupId = this.$route.params.groupId
      } else {
        this.groupId = ''
      }

      const queryKeys = Object.keys(this.$route.query)
      if (Array.isArray(queryKeys) && Array.from(queryKeys).length > 0) {
        for (let index = 0; index < queryKeys.length; index += 1) {
          const key = queryKeys[index];
          if (this.allowQueryKeys.some(list => list === key)) {
            if (!this.passedQuery) {
              this.passedQuery = true
            }

            let value = this.$route.query[key]
            if (key === "selectedGameCheckboxs") {
              value = Array.isArray(value) ? Array.from(value) : [value]
            } else if (key === 'isSimple') {
              value = Boolean(String(value).toUpperCase() === "TRUE")
            }

            this[key] = value
          }
        }
      }

      if (!this.passedQuery || this.selectedGameCheckboxs.length === 0) {
        this.selectedGame = 'All'
        this.selectedGameCheckboxs = GameLists.map(list => list.value)
      }
    },
    setRouteData() {
      this.routeData = {
        // diff
        name: this.routeName,
        params: {
          ...this.groupId && {
            groupId: this.groupId,
          },
        },
        query: {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          timeStart: this.timeStart,
          timeEnd: this.timeEnd,
          selectedGame: this.selectedGame,
          selectedGameCheckboxs: this.selectedGameCheckboxs,
          searchUsername: this.searchUsername,
          // t: Date.now(),
        },
      }
    },
    async onSearch() {
      if (!Array.isArray(this.selectedGameCheckboxs) || Array.from(this.selectedGameCheckboxs).length === 0) {
        this.$swal({
          icon: 'error',
          title: this.$t('PLEASE_SELECT_GAMES'),
        })
        return
      }

      this.setRouteData()
      this.$router.push(this.routeData).catch(error => {
        if (error.name === "NavigationDuplicated") {
          this.fetchData()
        } else {
          throw error;
        }
      })
    },
    async fetchData() {
      this.$wait.start('reports-loading')
      try {
        // diff url path
        let pathWithParam = `${this.urlPath}`
        if (this.groupId) {
          pathWithParam += `/${this.groupId}`
        }
        const { data } = await this.$http.post(pathWithParam, {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          timeStart: this.timeStart,
          timeEnd: this.timeEnd,
          selectedGame: this.selectedGame,
          selectedGameCheckboxs: this.selectedGameCheckboxs,
          searchUsername: this.searchUsername,
          group: this.group,
        })

        this.items = data?.data?.lists ?? []
        this.summary = data?.data?.summary ?? {}
        this.groupType = data?.data?.group?.type

        this.uplineType = this.groupType

        const responseSuccess = data?.success
        const responseMessage = data?.status?.message
        if (!responseSuccess) {
          this.$swal({
            icon: 'error',
            title: this.$t(responseMessage || 'Error'),
          })
          return
        }

        // if (this.groupType === 'MEMBER') {
        //   this.uplineType = 'AGENT'
        // } else if (this.groupType === 'AGENT') {
        //   this.uplineType = 'MASTER'
        // } else if (this.groupType === 'MASTER') {
        //   this.uplineType = 'SENIOR'
        // } else if (this.groupType === 'SENIOR') {
        //   this.uplineType = 'PARTNER'
        // } else if (this.groupType === 'PARTNER') {
        //   this.uplineType = 'COMPANY'
        // } else if (this.groupType === 'COMPANY') {
        //   this.uplineType = 'API'
        // }

        if (this.refKeyText !== 'Match') {
        // breadcrumb
          store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')

          const { uplinesGroup } = data.data
          if (uplinesGroup && Array.isArray(uplinesGroup) && Array.from(uplinesGroup).length > 0) {
            this.setRouteData()

            const queryAll = this.routeData?.query ?? {}
            for (let index = 0; index < uplinesGroup.length; index += 1) {
              const uplineGroup = uplinesGroup[index]
              const routeDataMaybeWithNewGroup = {
                ...this.routeData,
                params: {
                  ...this.routeData.params,
                  groupId: uplineGroup.id,
                },
              }
              store.commit('appBreadCrumb/ADD_BREADCRUMBS', {
                text: uplineGroup.username,
                custom: true,
                to: { ...routeDataMaybeWithNewGroup },
                active: index === uplinesGroup.length - 1,
              })
            }
            store.commit('appBreadCrumb/REPLACE_ROOT_QUERY_BREADCRUMBS', queryAll)
          }
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message || 'Error'),
        })
      } finally {
        this.$wait.end('reports-loading')
      }
    },
  },
}
</script>
