<template>
  <b-card-code class="table">
    <div class="d-flex justify-content-end align-items-center">
      <div>
        <VueGoodTableSwitchCompactMode />
      </div>
    </div>

    <!-- table -->
    <VueGoodTableRemote
      ref="tableData"
      :columns="columns"
      :columns-top="columnsTop"
      :rows="items"
      :line-numbers="true"
      theme="black-rhino"
      :is-loading="$wait.is('reports-loading')"

      :pagination-enabled="items.length > 3000"
      :total-records="items.length"
      table-mode="any"
    >
      <template slot="table-actions-bottom2">
        <tr class="tr-on-hover">
          <td
            :colspan="getTableActionsBottom2Margin"
            class="text-center black--text text-total"
          >
            <span>{{ $t('Summary') }}</span>
          </td>
          <td
            class="text-right black--text text-total"
          >
            {{ summary.memberCount }}
          </td>
          <td
            class="text-right text-total"
            :class="[Number(summary.amount) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.amount) }}
          </td>
          <td
            class="text-right text-total"
            :class="[Number(summary.validAmount) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.validAmount) }}
          </td>
          <td
            class="text-right black--text text-total"
          >
            {{ summary.stackCount }}
          </td>
          <td
            class="text-right text-total"
            :class="[Number(summary.grossComm) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.grossComm) }}
          </td>

          <!-- member -->
          <td
            class="text-right text-total"
            :class="[Number(summary.memberWinLose) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.memberWinLose) }}
          </td>
          <td
            class="text-right text-total"
            :class="[Number(summary.memberWinLoseCom) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.memberWinLoseCom) }}
          </td>
          <td
            class="text-right text-total"
            :class="[Number(summary.memberTotalWinLoseCom) < 0 ? 'red--text' : 'black--text', bgTotalCom]"
          >
            {{ DecimalFormat(summary.memberTotalWinLoseCom) }}
          </td>
          <!-- end member -->

          <template
            v-for="currentType in AgentTypes"
          >
            <td
              v-if="IsReportUpline({isSimple, uplineType, currentType})"
              :key="`footer-winlose-${currentType}`"
              class="text-right text-total"
              :class="[Number(summary[`${String(currentType).toLowerCase()}WinLose`]) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary[`${String(currentType).toLowerCase()}WinLose`]) }}
            </td>
            <td
              v-if="IsReportUpline({isSimple, uplineType, currentType})"
              :key="`footer-winloseCom-${currentType}`"
              class="text-right text-total"
              :class="[Number(summary[`${String(currentType).toLowerCase()}WinLoseCom`]) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary[`${String(currentType).toLowerCase()}WinLoseCom`]) }}
            </td>
            <td
              v-if="IsReportUpline({isSimple, uplineType, currentType})"
              :key="`footer-totalWinloseCom-${currentType}`"
              class="text-right text-total"
              :class="[Number(summary[`${String(currentType).toLowerCase()}TotalWinLoseCom`]) < 0 ? 'red--text' : 'black--text', bgTotalCom]"
            >
              {{ DecimalFormat(summary[`${String(currentType).toLowerCase()}TotalWinLoseCom`]) }}
            </td>
          </template>
        </tr>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <!-- <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div> -->

        <!-- Column: Action -->
        <span v-if="FieldNumberLists.some(list => list === props.column.field)">
          <span
            class="text-total"
            :class="[Number(props.formattedRow[props.column.field]) < 0 ? 'red--text' : 'black--text']"
          >{{ DecimalFormat(props.formattedRow[props.column.field]) }}</span>
        </span>
        <div
          v-else-if="props.column.field === '_id'"
        >
          <!-- <b-button
            class="link--text"
            variant="link"
            :to="onViewLists(props.row)"
          >
            {{ props.formattedRow[props.column.field] }}
          </b-button> -->
          <div class="d-flex flex-row justify-content-between">
            <div>
              <span
                :class="[refKeyText === 'Match' ? 'black--text' : 'link--text']"
                @click="onViewListsClick(props.row)"
              >{{ props.formattedRow[props.column.field] }}</span>
            </div>

            <div v-if="IsShowViewTransactionPlayer(props.row.group)">
              <b-tooltip
                :target="`tooltip-target-report-get-player-${props.formattedRow[props.column.field]}`"
                triggers="hover"
              >
                {{ $t('Get Transactions Player') }}
              </b-tooltip>
              <b-icon
                :id="`tooltip-target-report-get-player-${props.formattedRow[props.column.field]}`"
                icon="eye-fill"
                variant="primary"
                style="cursor: pointer"
                @click="onViewTransactionsPlayer(props.row)"
              />
            </div>
          </div>
        </div>

        <div
          v-else-if="props.column.field === 'contact'"
          class="reports-contact-text-truncate"
        >
          <span
            v-b-tooltip.hover
            :title="props.formattedRow[props.column.field]"
          >{{ props.formattedRow[props.column.field] }}</span>
        </div>
        <div
          v-else-if="props.column.field === 'stackCount'"
        >
          <span
            class="text-total black--text"
          >{{ DecimalFormatCustomFix(props.formattedRow[props.column.field], 0) }}</span>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="loadingContent">
        <div
          class="spinner-border text-primary"
          style="width: 3rem; height: 3rem;"
          role="status"
        >
          <span class="sr-only">{{ $t('Loading') }}...</span>
        </div>
      </template>
    </VueGoodTableRemote>
  </b-card-code>
</template>

<script>
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table-custom'
import { AgentTypes, IsReportUpline } from '@/utils/agent'
import { DecimalFormat, DecimalFormatCustomFix } from '@/utils/number'
import VueGoodTableRemote from '@/components/VueGoodTableRemote.vue'
import store from '@/store'

export default {
  components: {
    VueGoodTable,
    VueGoodTableSwitchCompactMode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCardCode,
    BButton,
    VueGoodTableRemote,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
    uplineType: {
      type: String,
      required: true,
    },
    groupType: {
      type: String,
      required: true,
    },
    isSimple: { type: Boolean, required: true },
    refKeyText: { type: String, required: true },
    // groupId: { type: String, required: true },
  },
  data() {
    return {
      AgentTypes,
    }
  },
  computed: {
    getTableActionsBottom2Margin() {
      if (this.refKeyText === 'Match') return '2'

      return '3'
    },
    getTableHeaderColspan() {
      if (this.refKeyText === 'Match') return 6

      return 7
    },
    bgTotalCom() {
      return 'table__tdclass__lists'
    },
    columnsTop() {
      const headers = [
        {
          colspan: this.getTableHeaderColspan,
        },
        {
          label: this.$t('Member'),
          colspan: 3,
          style: {
            'text-align': 'center',
          },
        },
      ]
      if (Array.isArray(AgentTypes)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const currentType of AgentTypes) {
          if (IsReportUpline({ isSimple: this.isSimple, uplineType: this.uplineType, currentType })) {
            let currentTypeName = ''
            try {
              currentTypeName = String(currentType).charAt(0).toUpperCase() + String(currentType).slice(1).toLowerCase()
            // eslint-disable-next-line no-empty
            } catch (error) {
            }
            headers.push({
              label: this.$t(currentTypeName),
              colspan: 3,
              style: {
                'text-align': 'center',
              },
            })
          }
        }
      }
      return headers
    },
    columns() {
      const headers = [
        {
          // label: String(this.refKeyText).toUpperCase(),
          label: String(this.refKeyText)[0].toUpperCase() + String(this.refKeyText).slice(1),
          field: '_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ...',
            formatValue(value) {
              return String(value).trim()
            },
          },
          sortable: false,
          width: '120px',
        },
        {
          label: 'MemberCount',
          field: 'memberCount',
          type: 'number',
          tdClass: 'black--text',
          width: '70px',
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
        },
        {
          label: 'ValidAmount',
          field: 'validAmount',
          type: 'number',
          width: '80px',
        },
        {
          label: 'StackCount',
          field: 'stackCount',
          type: 'number',
          tdClass: 'black--text',
        },
        {
          label: 'GrossComm',
          field: 'grossComm',
          type: 'number',
        },
        {
          label: 'W/L',
          field: 'memberWinLose',
          type: 'number',
          width: '80px',
        },
        {
          label: 'Comm',
          field: 'memberWinLoseCom',
          type: 'number',
        },
        {
          label: 'W/L Comm',
          field: 'memberTotalWinLoseCom',
          type: 'number',
          tdClass: this.bgTotalCom,
        },
      ]

      if (this.refKeyText !== 'Match') {
        headers.splice(1, 0, {
          // label: String(this.refKeyText).toUpperCase(),
          label: 'Contact',
          field: 'contact',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ...',
            formatValue(value) {
              return String(value).trim()
            },
          },
          sortable: false,
          // width: '100px',
        })
      }

      if (Array.isArray(AgentTypes)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const currentType of AgentTypes) {
          if (IsReportUpline({ isSimple: this.isSimple, uplineType: this.uplineType, currentType })) {
            const lists = [
              {
                label: 'W/L',
                field: `${String(currentType).toLowerCase()}WinLose`,
                type: 'number',
                width: '80px',
              },
              {
                label: 'Comm',
                field: `${String(currentType).toLowerCase()}WinLoseCom`,
                type: 'number',
              },
              {
                label: 'W/L Comm',
                field: `${String(currentType).toLowerCase()}TotalWinLoseCom`,
                type: 'number',
                tdClass: this.bgTotalCom,
              },
            ]
            headers.push(...lists)
          }
        }
      }

      return headers
    },
    FieldNumberLists() {
      return [
        "apiTotalWinLoseCom",
        "apiWinLoseCom",
        "apiWinLose",
        "companyTotalWinLoseCom",
        "companyWinLoseCom",
        "companyWinLose",
        "partnerTotalWinLoseCom",
        "partnerWinLoseCom",
        "partnerWinLose",
        "seniorTotalWinLoseCom",
        "seniorWinLoseCom",
        "seniorWinLose",
        "masterTotalWinLoseCom",
        "masterWinLoseCom",
        "masterWinLose",
        "agentTotalWinLoseCom",
        "agentWinLoseCom",
        "agentWinLose",
        "memberTotalWinLoseCom",
        "memberWinLoseCom",
        "memberWinLose",
        "grossComm",
        // "stackCount",
        "validAmount",
        "amount",
      ]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    userData() {
      return store.getters['auth/user']
    },
  },
  mounted() {
  },
  methods: {
    IsReportUpline,
    DecimalFormat,
    DecimalFormatCustomFix,
    IsShowViewTransactionPlayer(rowGroup) {
      // const rowGroup = row.group
      const groupType = rowGroup?.type
      return groupType && !['API', 'COMPANY', "PARTNER"].includes(groupType)
    },
    onViewTransactionsPlayer(row) {
      const rowGroup = row.group

      const groupType = rowGroup.type
      const groupId = rowGroup._id

      // const uplineGroupType = rowGroup.upline.type
      const uplineGroupId = rowGroup.upline._id

      const isGroupMember = groupType === 'MEMBER'

      // api, company, partner, senior, master
      const routeData = this.$router.resolve({
        name: this.isSimple ? 'reports-player-winlose-simple' : 'reports-player-winlose-detail',
        params: {
          ...!isGroupMember && { groupId },
        },
        query: {
          ...this.$route.query,
          searchUsername: '',
          isSimple: this.isSimple,
        },
      });
      window.open(routeData.href, '_blank');
    },
    onViewListsClick(row) {
      if (this.refKeyText === 'Match') {
        return
      }
      this.$router.push(this.onViewLists(row))
    },
    onViewLists(row) {
      // const refKeyText = this.refKeyText // Match, Username
      if (this.refKeyText === 'Match') {
        // match
        return {
          name: 'reports-match-winlose-lists',
          query: {
            ...this.$route.query,
            searchUsername: '',
            selectedGame: row._id,
            selectedGameCheckboxs: [row._id],
            isSimple: this.isSimple,
          },
        }
      }

      const rowGroup = row.group

      const groupType = rowGroup.type
      const groupId = rowGroup._id

      // const uplineGroupType = rowGroup.upline.type
      const uplineGroupId = rowGroup.upline._id

      const isOwner = groupId === uplineGroupId
      if (isOwner || groupType === 'AGENT' || groupType === 'MEMBER') {
        return this.onViewTransactionsPlayer(row)
      }
      // if (groupType === 'MEMBER') {
      //   return {
      //     name: 'reports-member-winlose-lists',
      //     params: {
      //     // ...this.groupId && { groupId: this.groupId },
      //       ...uplineGroupId && { groupId: uplineGroupId },
      //     },
      //     query: {
      //       ...this.$route.query,
      //       searchUsername: row._id,
      //       isSimple: this.isSimple,
      //     },
      //   }
      // }

      // api, company, partner, senior, master
      return {
        // name: this.isSimple ? 'reports-agent-winlose-simple' : 'reports-agent-winlose-detail',
        name: this.$route.name,
        params: {
          groupId,
        },
        query: {
          ...this.$route.query,
          searchUsername: '',
          isSimple: this.isSimple,
        },
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
// @import '@/assets/scss/table-list.scss';

// .tr-on-hover:hover {background-color:#664cfd2c!important;}
// .text-total {
//   // font-size: 11.5px!important;
//   font-weight: bold!important;

// }
// .red--text {
//   color: red!important;
//   // font-weight: bold;
// }
// .black--text {
//   color: black!important;
//   // font-weight: bold;
// }
// .link--text {
//   cursor: pointer;
//   color: #00f;
//   font-weight: bold;
// }

</style>
